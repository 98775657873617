<template>
  <main class="pr">
    <section class="core row-between">
      <sidebar v-if="!$route.meta.hideSidebar" />
      <div class="defaultModel" :class="{ model: !$route.meta.hideSidebar }">
        <router-view></router-view>
      </div>
    </section>
  </main>
</template>
<script>
import Sidebar from "../components/Sidebar";
export default {
  name: "User",
  props: {},
  components: {
    sidebar: Sidebar,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
main {
  background-color: $-color-white;
}
section {
  align-items: flex-start;
  padding: 44px 0 34px;
}
.defaultModel {
  width: 100%;
  &.model {
    width: 922px;
  }
}
</style>
